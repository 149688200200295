#toast__container * {
  box-sizing: border-box;
}

.toast__message {
  opacity: 0;
  z-index: 1000;
  width: max-content;
  max-width: 90%;
  transition: opacity .3s, transform .3s;
  position: fixed;
}

.toast__message--top-center, .toast__message--bottom-center, .toast__message--center {
  left: 50%;
}

.toast__message--enter-active, .toast__message--appear-active {
  opacity: 1;
}

.toast__message--exit-active {
  opacity: 0;
}

.toast__message--loading .toast__spinner-wrap {
  visibility: visible;
  opacity: 1;
  width: 1.1em;
  margin-right: 12px;
}

.toast__content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.toast__content--clickable {
  cursor: pointer;
}

.toast__theme-content {
  transition: all .2s;
}

.toast__spinner-wrap {
  visibility: hidden;
  opacity: 0;
  width: 0;
  margin-right: 0;
  display: inline-block;
}

.toast__spinner {
  text-indent: -99999px;
  border: 2px solid #ffffff4d;
  border-top-color: #fff;
  border-radius: 50%;
  width: 1.1em;
  height: 1.1em;
  animation: 1s ease-in-out infinite toast_spinner-spin;
  display: block;
  overflow: hidden;
}

@keyframes toast_spinner-spin {
  to {
    transform: rotate(360deg);
  }
}

.toast__dark {
  color: #fff;
  text-align: center;
  vertical-align: top;
  white-space: pre-line;
  background: #00000acc;
  border-radius: 40px;
  min-width: 200px;
  padding: 12px 22px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  box-shadow: 1px 2px 5px 1px #0003;
}
/*# sourceMappingURL=warmspace-session-ui.0a601926.css.map */
